
.contactPageContainer{
    width: 100vw;
    height: 50vh;
}
.contactPage{
    width: fit-content;
    padding: 40px;
    border: 2px solid black;
    border-radius: 15px;
}