.messageContainer{
    position:relative;
    width:100%
}

.messageHighlight{
    background-color: rgb(255, 226, 231);
}

.messageText{
    width:100%;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
}
.reactionsContainer{
    width: fit-content;   
    margin-left: 35px;
}

.menuContainer{
    background-color: var(--dark-red);
    position: absolute;
    width: max-content;
    top: 0;
    left: 100%;

    border-radius: 5px;
    z-index: 2;
}
.messageOptionContainer{
    position: relative;
}
.menuText{
    margin: 0;
    padding: 7px;
    border-radius: 5px;
    transition-duration: 100ms;
}
.optionsSVG{
    margin: 2px 5px;
    padding: 5px;
    border-radius: 100%;
}
.optionsSVG:hover{
    background-color: rgb(210, 210, 210);
}
.reportWindow{
    position: relative;
    border-radius: 15px;
    border: 2px solid black;
    padding: 40px;
    width: 360px;
    height:fit-content;

}
.closeWindowSVG{
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    border-radius: 100%;
    padding: 5px;
    transition-duration: 100ms;
}
.closeWindowSVG:hover{
    background-color: rgb(226, 226, 226);
}
.submitReport{
    width: fit-content;
    margin: 15px;
    margin-left: 0;
    padding: 10px;   
    border-radius: 5px;
    background-color: black;
    font-size: large;
}
.badMessageDisplay{
    border: 1px solid black;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 10px;
    max-height:150px;
    overflow-y: auto;
}

.reportInput{
    font-size: large;
}
.reportInput:focus{
    outline: none;
}

.testContainer{
    background-color: var(--dark-red);
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left:400px;
    top:400px;
}
.reactionOption{
    position: relative;
}

.emojisContainer{
    background-color: var(--dark-red);
    position: absolute;
    top: 0;
    left: 108%;
    border-radius: 5px;
    z-index: 2;
    padding: 0px 10px;
}
.emojisBox{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.emoji{
    margin: 10px;
    padding: 0 2px;
    border-radius: 5px;
    height: fit-content;
    width: fit-content;

}
.removeReactionBtn{
    color:white;
    border: none;
    border-radius: 2px;
    margin-top: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 15px;
    transition-duration: 0ms;
}

.line{
    height: 20px;
    width: 65px;
    border-radius: 10px;
    border: 2px solid rgb(106, 106, 106);
    border-bottom: none;
    border-right: none;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-left: 35px;
    margin-top:15px;
    position:relative
}
.replyInfo{
    position: absolute;
    left: 70px;
    top: -50%;
    display: flex;
    width: max-content;
}