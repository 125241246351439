.raleway{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
/* <uniquifier>: Use a unique and descriptive class name */
/* <weight>: Use a value from 200 to 1000 */

.nunito-sans-light {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.nunito-sans-regular {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.nunito-sans-bold {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}
.small{
  font-size: 15px;
}
.regular{
  font-size: 25px;
}