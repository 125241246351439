#root{
    --chatroomWidth:100%;    
}
body{
    margin:0
}

.chatroomContainer{
    height: 100%;
    margin: 0;
    padding: 0;
}
.chatroom{
    position:relative;
    overflow: auto;
    scroll-behavior: smooth;

    /* overflow: visible; */
    height: 100%;
    width: var(--chatroomWidth);
    word-break: break-word;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.notificationContainer{
    position:absolute;
    top:0;
    left:10px;
    width:fit-content;
    z-index:4;
}
.notification{
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: rgb(26, 97, 74);
    padding: 10px 5px
}
.formContainer{
    width: 100%;
    z-index:6
}
.messageForm{
    height: 55px;
    display: flex;
    width: var(--chatroomWidth);
    border-top:2px solid black
}

.darker{
    background-color: #cf3b56;
}
.messageInput{
    width: 100%;
    margin: 0 5px;
    border:none;
    border-radius: 10px;
    font-size: 17px;
    margin-right: 5px;
    color: white;
}
.messageInput:focus{
    outline: none;
}
.messageInput::placeholder{
    color: rgb(255, 255, 255);
}
.listOfMessages{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin:0
}
.chatHeader{
    position: relative;
    width: var(--chatroomWidth);
    border-bottom: 2px solid #222828;
}
.vl{
    height: 100%;
    width: 2px;
    /* background-color: #fbc5cf; */
}
.newChat{
    border-radius: 15px;
    /* position: absolute; */
    position: fixed;
    right: 30px;
    padding: 0px 15px;
    background-color: var(--darker-red);

}
.errorPageContainer{
    height: 100vh;
}
.errorPage{
    width:fit-content;
    height:fit-content;
}

.sendButton{
    height: fit-content;
    border-radius: 11px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3px;
    margin-right:5px;
    padding: 7px 10px;
}
.replyingContainer{
    background-color: rgb(255, 188, 188);
    width:95%;
    padding: 0 2.5%
}