.startPageContainer{
    height:100%
}
 
.nameInput{
    margin-right: 10px;
    margin-left: 10px;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 2px solid black;
    font-size:30px;
    border-radius: 0%;
}
.nameInput:focus{
    outline:none;
}
.enterButton{
    border-radius: 100%;
    aspect-ratio: 1;
    border: 2px solid black;
    cursor: pointer;
    transition-duration: 350ms;
    background-color: white;
}
.enterButton:hover{
    background-color: rgb(212, 212, 212);
}
.formComponents{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 650px){
    .formComponents{
        flex-direction: column;
    }
}
@media (max-width: 450px){
    .enterForm{
        flex-direction: column;
    }
    .enterButton{
        margin-top: 50px;
    }
}