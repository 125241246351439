

.homePageContainer{
    position: relative;
    height:100%;
}

.startChatBtn{
    padding: 5px 100px;
    border-radius: 20px;
    margin: 30px;
}
.welcomeText{
    margin-bottom: 0;
}
.searchingText{
    margin-top: 30px;

}
.signOutButton{
    border-radius: 100%;
    aspect-ratio: 1;
    border: 2px solid black;
    cursor: pointer;
    transition-duration: 350ms;
    background-color: white;
    transform: rotate(180deg);
    margin-right: 10px;
}
.signOutButton:hover{
    background-color: rgb(212, 212, 212);
}

.disclaimerContainer{
    position: absolute;
    bottom: 30px;
    /* left: -50%; */
}
.headerContainer{
    display: flex;
    align-items: center;
    position: absolute;
    top:5px;
    width:97%;
    justify-content: space-between;   
}
@media (max-width: 530px){
    .headerContainer{
        flex-direction: column;
        align-items: start;
    }
}