body{
    --dark-red:#cf3b56;
    --darker-red:#7e2435;
}
.blackBG{
    background-color: black;
}
.whiteBG{
    background-color: white;
}
.redBG{
    background-color: var(--dark-red);
}
.redBGhover{
    background-color: var(--dark-red);
    transition-duration: 400ms;
}
.redBGhover:hover{
    background-color: var(--darker-red);
}
.pointer{
    cursor:pointer
}


.whiteText{
    color: white;
}
.blackText{
    color: black;
}
.noBorder{
    border: 0;
}